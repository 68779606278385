<template>
  <div>
    <topnav></topnav>

    <div class="land-page">
      <div class="land-box">
        <!--    v-if="1 == 2" -->
        <div class="land-box-inner">
          <p class="land-top">Tamdeini invites you to a</p>
          <h1 class="land-page-head">Parlor Meeting</h1>
          <p class="land-hosted">Hosted by Rabbi Daniel Waldman</p>
          <p class="land-mid">
            1314 Caffrey Avenue, Far Rockaway, NY 11691
            <br />
            Monday, May 15th at 9:30 PM
          </p>
          <div class="land-hor-line"></div>

          <p>9:45 PM Guest Speaker</p>
          <p class="land-funcy">Rabbi aakov Bender, Sura</p>
          <p class="land-end-txt">
            Looking forward to greeting you,
            <br />
            Meir Oratz
          </p>
          <div class="land-hor-line"></div>

          <p>HOST COMMITEE</p>
          <div class="landCOMMITEE">
            <div>
              Yehuda Deutsch<br />
              Yacov Asher Engel <br />
              Yehoshua Fleisher <br />
              Asher Genish <br />
              Mordechai Gutfreund
            </div>
            <div>
              Yaron Halbertal <br />
              Moshe Chaim Horowitz <br />
              Mosey Kaplan <br />
              Eitan Katz <br />
              Naftali Kolodny
            </div>
            <div>
              Mordechai Rosen <br />
              Ezra Rosner <br />
              Elie Schwab <br />
              Meyer Simcha Schwartz <br />
              Moshe Wischogrodski
            </div>
          </div>

          <router-link to="/donate" class="land-btn">Donate</router-link>
           <!-- <div class="land-form-wrap">
          <form
            @submit.prevent="submitForm"
            v-if="formStatus != 'success'"
            class="land-form"
          >
          <p>Fill the form to submit a Donation</p>

              <input
                placeholder="Your Name (required)"
                type="text"
                name="name"
                class="land-field"
                required=""
                v-model="form.fullName"
              />
              <input
                placeholder="Amount in USD"
                type="number"
                name="Amount"
                class="land-field"
                required=""
                v-model="form.amount"
              />
              <input
                v-model="form.phone"
                placeholder="Your Phone (required)"
                type="tel"
                name="phone"
                required=""
                class="land-field"
              />

              <textarea v-model="form.message" placeholder="Your Message" class="land-field big"></textarea>

              <input type="submit" value="Submit" class="land-btn" />
          </form>

          <div class="form-fail" v-if="formStatus == 'err'">
            <p>Oops! Something went wrong while submitting the form.</p>
          </div>
          <div class="form-success" v-if="formStatus === 'success'">
            <p>
              Thank you!
              <br />
              Your submission has been received!
            </p>
          </div>
        </div> -->
        </div>
      </div>
      <!-- <img src="../assets/ParlorMeeting.jpeg" /> -->
      <div class="land-bg" />
    </div>
  </div>
</template>

<script>
import Topnav from "../components/Topnav.vue";

export default {
  components: { Topnav },
  data() {
    return {
      popupactive: false,
      form: {
        fullName: "",
        amount: "",
        phone: "",
        message: "",
        page:"far-rockaway",
      },
      formStatus: "",
    };
  },
  methods: {
    async submitForm() {
      if (this.submitting) return;
      try {
        this.submitting = true;
        await this.$db.collection("donation").post(this.form);
        this.formStatus = "success";
        this.submitting = false;
      } catch (err) {
        this.formStatus = "err";
        this.submitting = false;
      }
    },
  },
  name: "ParlotMeeting",
};
</script>
<style scoped>
.land-top {
  margin: 40px 0 -30px;
}
.land-hosted {
  margin: -20px 0 30px;
}
.landCOMMITEE {
display: flex;
gap: 30px;
justify-content: center;
font-size: 0.8em;
line-height: 2;
margin: 30px 0;
flex-wrap: wrap;
}
.landCOMMITEE div{
  flex: 1 0 200px;
}
@media screen and (max-width: 767px) {
  .land-top {
  margin: 20px 0 -20px;
}
.land-hosted {
  margin: -10px 0 30px;
}
}


</style>
